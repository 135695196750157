import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import { user } from './user.module'
import { alert } from './alert.module'
import { sidebar } from './sidebar.module'
import { clients } from './clients.module'
import { backpack } from './backpack.module'
import { login_history } from './login_history.module'
import { store } from './store.module'
import { agency } from './agency.module'
import { apply_history } from './apply_history.module'
import { trans_history } from './trans_history.module'
import { commodity_type } from './commodity_type.module'
import { nodes } from './nodes.module'
// import SecureLS from "secure-ls";
// var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)
// const userToken = createPersistedState.getItem('token'))
// window.console.log(userToken)
export default new Vuex.Store({
  // strict: true,
  // plugins: [
  //   createPersistedState({
  //     storage: {
  //       getItem: key => ls.get(key),
  //       setItem: (key, value) => ls.set(key, value),
  //       removeItem: key => ls.remove(key)
  //     }
  //   })
  // ],
  modules: {
      user,
      alert,
      sidebar,
      clients,
      backpack,
      login_history,
      store,
      agency,
      apply_history,
      trans_history,
      commodity_type,
      nodes
  }
})
