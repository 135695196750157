import AuthenticationService from '@/services/AuthenticationService'
import  i18n  from '@/i18n.js'

const state = {
  loaded: false,
  goods: []
};

const actions = {
    async get_backpack({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_backpack(token);
          if (resp.status == '200') {
            commit('setBackPack', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async apply_self({commit, dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.apply_self(token, info);
          if (resp.status == '201') {
            dispatch('alert/success', i18n.t('backpack.activate_success'), { root: true });
            commit('reload_nextime');
            dispatch('user/reload_nextime',{}, { root: true });
            dispatch('apply_history/reload_nextime',{}, { root: true });            
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('backpack.activate_fail'), { root: true })
          }
    },
    async deposit({ dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.warehouse_deposit(token, info);
          if (resp.status == '201') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true })
          }
    },
    clear({ commit }) {
        commit('clear');
    },
    reload_nextime({ commit }) {
        commit('reload_nextime');
    }
};

const mutations = {
    setBackPack(state, _goods) {
        state.goods = _goods;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.clients = null;
    },
    reload_nextime(state) {
      state.loaded = false;
    }
};

export const backpack = {
    namespaced: true,
    state,
    actions,
    mutations
};
