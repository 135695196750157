import AuthenticationService from '@/services/AuthenticationService'
import  i18n  from '@/i18n.js'

const state = {
  loaded: false,
  client: {}
};

const actions = {
    set_client({ commit }, client ) {
        commit('set_client', client);
    },
    async apply({ dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.apply(token, info);
          if (resp.status == '201') {
            dispatch('alert/success', i18n.t('backpack.charge_success'), { root: true });
            dispatch('clients/reload_nextime',{}, { root: true });
            dispatch('backpack/reload_nextime',{}, { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('backpack.charge_fail'), { root: true })
          }
    },
    async transfer({ dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.transfer(token, info);
          if (resp.status == '201') {
            dispatch('alert/success', i18n.t('backpack.transfer_success'), { root: true });
            dispatch('backpack/reload_nextime',{}, { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('backpack.transfer_fail'), { root: true })
          }
    },
    async change_pwd({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.change_pwd(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('changepwd.success'), { root: true });
          // dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('changepwd.fail'), { root: true })
        }
    },    
    async stop_account({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.stop_account(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.stoped'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },      
    async suspend_account({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.suspend_account(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.suspend'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },      
    async resume_account({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.resume_account(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.resume'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },   
    async change_name({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.change_name(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.changename'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },   
    async change_cmid({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.change_cmid(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.changename'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },      
    async clear_devices({ dispatch, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.clear_devices(token, info);
        if (resp.status == '201') {
          dispatch('alert/success', i18n.t('account.cleardevices'), { root: true });
          dispatch('backpack/reload_nextime',{}, { root: true });
        }
      } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true })
        }
    },         
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    set_client(state, _client) {
        state.client = _client;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.client = {};
    }
};

export const agency = {
    namespaced: true,
    state,
    actions,
    mutations
};
