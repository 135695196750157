import AuthenticationService from '@/services/AuthenticationService'
import Vue from 'vue'

const state = {
  loaded: false,
  clients: []
};

const actions = {
    setClients({ commit }, clients) {
        commit('setClients', clients);
    },
    async get_clients({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_clients(token);
          let new_clients = [];
          if (resp.status == '200') {
            for (const item in resp.data.data) {
              let exd = Vue.filter('formatDate')(resp.data.data[item].expired_date);
              new_clients.push({email:resp.data.data[item].email,
                                diamond: resp.data.data[item].diamond,
                                expired_date: exd,
                                devices_limit: resp.data.data[item].devices_limit,
                                commodity_id: resp.data.data[item].commodity_id,
                                is_suspend: resp.data.data[item].is_suspend,
                                create_time: resp.data.data[item].create_time
                              });
            }
            commit('setClients', new_clients)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async search_users_alike({ commit, rootGetters }, info) {
      try {
        // window.console.log(store.getters);
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.search_users_alike(token, info);
        let new_clients = [];
        if (resp.status == '200') {
          for (const item in resp.data.data) {
            let exd = Vue.filter('formatDate')(resp.data.data[item].expired_date);
            // window.console.log(resp.data.data[item].all_recharge_gold)
            // window.console.log(resp.data.data[item].all_recharge_gold/10000)
            new_clients.push({email:resp.data.data[item].email,
              diamond: resp.data.data[item].diamond,
              expired_date: exd,
              devices_limit: resp.data.data[item].devices_limit,
              commodity_id: resp.data.data[item].commodity_id,
              is_suspend: resp.data.data[item].is_suspend,
              create_time: resp.data.data[item].signup_timestamp
            });
          }
          commit('setClients', new_clients)
        }
      } catch(err) {
          window.console.log(err)
        }
  },      
    clear({ commit }) {
        commit('clear');
    },
    reload_nextime({ commit }) {
        commit('reload_nextime');
      }
};

const mutations = {
    setClients(state, _clients) {
        state.clients = _clients;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.clients = null;
    },
    reload_nextime(state) {
      state.loaded = false;
    }
};

export const clients = {
    namespaced: true,
    state,
    actions,
    mutations
};
