<template>
  <v-app class="email">
    <v-layout
    align-center
    justify-center
    >
      <v-flex ln-footer>
        <v-btn
          @click="verifyHandle"
        >
          {{ $t('email.verify') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-footer
      color="#fbfbfb"
      height="auto"
    >
      <v-layout
      align-center
      justify-center
      >
        <v-flex rg-footer>
      <router-link :to="{ name: 'login' }">{{ $t('register.goback') }}</router-link>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import {  mapActions } from 'vuex';

export default {
  name: 'Email',
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('user', ['verify']),
    verifyHandle: function() {
      const info = {
        email: this.$route.params.email,
        verify_code: this.$route.params.verify_code,
      }
      window.console.log(info)
      this.verify(info)
    }
  },
};
</script>
