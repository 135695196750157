<template>
  <div class="password">
    <v-layout
      align-center
      justify-center
    >
      <v-flex class="rg-frame">
    <h1>{{ $t('changepwd.title') }}</h1>
    <v-form ref="form" class="cg-form">
      <v-text-field
        v-model="form.password"
        :rules="passwordRules"
        prepend-icon="fa-lock"
        @keyup.enter.native="login"
        :append-icon="showPwd ? 'fa-eye-slash' : 'fa-eye'"
        :type="showPwd ? 'text' : 'password'"
        @click:append="showPwd = !showPwd"
        :label="$t('changepwd.pwd')"
        required
      ></v-text-field>
      <v-text-field
        v-model="form.password2"
        :rules="passwordRules2"
        prepend-icon="fa-redo"
        @keyup.enter.native="login"
        :type="showPwd ? 'text' : 'password'"
        @click:append="showPwd = !showPwd"
        :label="$t('register.repeat-password')"
        required
      ></v-text-field>
      <v-layout
        class="rg-panel"
        column
        wrap
        justify-center
        align-center
      >
      <v-flex>
        <v-btn
          @click="changeHandle"
        >
          {{ $t('changepwd.btn') }}
        </v-btn>
      </v-flex>
      </v-layout>
    </v-form>
  </v-flex>
  </v-layout>
  <v-footer
    color="#fbfbfb"
    height="auto"
  >
    <v-layout
    align-center
    justify-center
    >
      <v-flex rg-footer>
    <router-link :to="{ name: 'login' }">{{ $t('register.goback') }}</router-link>
      </v-flex>
    </v-layout>
  </v-footer>
  </div>
</template>
<script>
import {  mapActions } from 'vuex';
// mapState
export default {
  name: 'Password',
  data () {
    return {
      showPwd: false,
      form: {
        password: '',
        password2: '',
      },
      passwordRules: [
      v => !!v || this.$t("login.password-required"),
      v => (v && v.length >= 6) || this.$t("login.password-at-less"),
      v => (v && v.length <= 20) || this.$t("login.password-limit"),
      ],
      passwordRules2: [
      v => !!v || this.$t("login.password-required"),
      v => (v && v.length >= 6) || this.$t("login.password-at-less"),
      v => (v && v.length <= 20) || this.$t("login.password-limit"),
      v => (v && v === this.form.password) || this.$t("register.same-password"),
      ],
    };
  },
  methods: {
    ...mapActions('user', ['changepwd']),
    ...mapActions('user', ['get_changepwd']),
    ...mapActions('alert', ['clear']),
    changeHandle: function() {
      if (this.$refs.form.validate()) {
        const info = {
          email: this.$route.params.email,
          code: this.$route.params.code,
          password: this.form.password,
        }
        window.console.log(info)
        this.changepwd(info)
        }
    }
  },
  mounted() {
    this.$nextTick(function () {
        // this.clear();
        const info = {
          email: this.$route.params.email,
          code: this.$route.params.code,
        }
        // window.console.log(info)
        this.get_changepwd(info)
      })
  },
};
</script>
