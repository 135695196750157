import axios from 'axios'
// import store from '@/store/store'

export default (token) => {
  return axios.create({
    baseURL: process.env.AXIOS_BASE_URL || 'https://admin.z9p.net:8000',
    headers: {
      Authorization: 'Bearer '+ token,
    }
  })
}
