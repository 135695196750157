<template>
  <v-app class="app-wrapper">
    <v-app-bar
      app
      color="white"
      light
    >
      <v-app-bar-nav-icon color="amber darken-4" @click.stop="toggleSidebar()"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
      <v-spacer />
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="amber darken-4">fa-chevron-circle-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group v-model="model">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title @click="logoutHandle">{{ $t("dashboard.logout") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
      <base-langbar v-on:langchange="OnLangChange"/>
    </v-app-bar>
    <app-sidebar
      ref="sidebar"
      class="sidebar-container"
    />
    <div
      id="main-container"
      class="main-container"
    >
    <app-main />
    </div>
    <app-fab />
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="alert.type"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{alert.message}}
      <v-btn
        dark
        text
        @click="clearAlert"
      >
        {{ $t('common.close') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppSidebar from './AppSidebar.vue';
import AppMain from './AppMain.vue';
import AppFab from './AppFab.vue';
import BaseLangbar from '@/components/BaseLangbar.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppLayout',
  components: {
    AppFab,
    AppSidebar,
    AppMain,
    BaseLangbar,
  },
  data() {
    return {
      model: 0,
      color: '',
      mode: '',
      // snackbar: false,
      text: 'Hello, I\'m a snackbar',
      timeout: 3000,
      x: null,
      y: 'top',
    };
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions('sidebar', ['change_language']),
    ...mapActions({
        clearAlert: 'alert/clear'
    }),
    toggleSidebar() {
      if (this.$refs.sidebar) {
        this.$refs.sidebar.toggleSidebar();
      }
    },
    logoutHandle() {
      this.logout();
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      this.$router.push("/home/login");
    },
    OnLangChange() {
      this.change_language();
    }
  },
  computed: {
      ...mapState({
          alert: state => state.alert
      }),
      snackbar: {
        get () {
          return !(this.alert.message === null)
        },
        set () {
          this.clearAlert()
        }
      }
  },
};
</script>
