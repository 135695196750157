<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import Login from './views/TheLogin';

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // Login
  },
  data: () => ({
    //
  }),
};
</script>
