<template>
  <v-navigation-drawer
    id="sidebar"
    class="blue-grey lighten-5"
    app
    fixed
    width="232"
    v-model="drawer"
    light
    mobile-break-point="400"
    :mini-variant="miniVariant">
      <v-list-item @click.stop="getMyinfo()">
        <v-list-item-avatar>
          <v-icon color="blue lighten-1">fa-user</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ this.user.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list rounded>
        <v-list-item
          v-for="item in this.sidebar.user_items"
          :key="item.title"
          @click="clickHandle(item)"
          link
        >
          <v-list-item-icon>
            <v-icon color="blue darken-1">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list rounded>
        <v-list-item
          v-for="item in this.sidebar.general_items"
          :key="item.title"
          @click="clickHandle(item)"
          link
        >
          <v-list-item-icon>
            <v-icon color="amber darken-4">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list rounded v-if="this.user.user.is_agency">
        <v-list-item
          v-for="item in this.sidebar.agency_items"
          :key="item.title"
          @click="clickHandle(item)"
          link
        >
          <v-list-item-icon>
            <v-icon color="amber darken-4">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list rounded v-if="this.user.user.is_admin">
        <v-list-item
          v-for="item in this.sidebar.admin_items"
          :key="item.title"
          @click="clickHandle(item)"
          link
        >
          <v-list-item-icon>
            <v-icon color="amber darken-4">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AppSidebar',
  data() {
    return {
      drawer: null,
      miniVariant: false,
      // miniVariant: true,
      ps: null,
    };
  },
  methods: {
    ...mapActions('user', ['get_myinfo']),
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
    getMyinfo() {
      window.console.log(this.user.user.is_agency);
      if (this.$router.currentRoute.name != 'Dashboard') {
        this.$router.push("/dashboard");
      }
    },
    clickHandle(item) {
      if ( item.icon === 'fa-users' && this.$router.currentRoute.name != 'Clients') {
        this.$router.push("/clients");
      } else if ( item.icon === 'fa-box-open' && this.$router.currentRoute.name != 'BackPack') {
        this.$router.push("/backpack");
      } else if ( item.icon === 'fa-address-card' && this.$router.currentRoute.name != 'LoginHistory') {
        this.$router.push("/login_history");
      } else if ( item.icon === 'fa-store-alt' && this.$router.currentRoute.name != 'Store') {
        this.$router.push("/store");
      } else if ( item.icon === 'fa-book-open' && this.$router.currentRoute.name != 'Charges') {
        this.$router.push("/charges");
      } else if ( item.icon === 'fa-dolly-flatbed' && this.$router.currentRoute.name != 'TransferCharge') {
        this.$router.push("/transfer_charge");
      } else if ( item.icon === 'fa-user-edit' && this.$router.currentRoute.name != 'TransHistory') {
        this.$router.push("/transfer_history");
      } else if ( item.icon === 'fa-fighter-jet' && this.$router.currentRoute.name != 'CommodityType') {
        this.$router.push("/commodity_type");
      } else if ( item.icon === 'fa-cloud-sun' && this.$router.currentRoute.name != 'Nodes') {
        this.$router.push("/nodes");
      }
    }
  },
  computed: {
      ...mapState({
          sidebar: x => x.sidebar,
          user: x => x.user,
      })
  },
  beforeMount() {
    if ( !this.user.user_loaded && this.$router.currentRoute.name != 'Dashboard' ) {
      this.get_myinfo();
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (window.matchMedia('screen and (max-width: 768px)').matches) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
      })
  },
};
</script>
