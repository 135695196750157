import AuthenticationService from '@/services/AuthenticationService'
// import Vue from 'vue'

const state = {
  loaded: false,
  trans_his: []
};

const actions = {
    async get_trans_history({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_trans_history(token);
          if (resp.status == '200') {
            commit('setTransHistory', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    setTransHistory(state, _his) {
        state.trans_his = _his;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.trans_his = [];
    }
};

export const trans_history = {
    namespaced: true,
    state,
    actions,
    mutations
};
