import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layouts/AppLayout'
import TheLogin from '@/views/TheLogin'
import Login from '@/views/home/Login'
import Register from '@/views/home/Register'
import Reset from '@/views/home/Reset'
import Email from '@/views/home/Email'
import Password from '@/views/home/Password'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/home',
      component: TheLogin,
      children: [{
        path: 'login',
        name: 'login',
        component: Login
      },{
        path: 'register',
        name: 'register',
        component: Register
      }, {
        path: 'reset',
        name: 'reset',
        component: Reset
      },
      {
        path: 'email/:email/:verify_code',
        name: 'email',
        component: Email
      },
      {
        path: 'password/:email/:code',
        name: 'password',
        component: Password
      }]
    },
    {
      path: '/',
      name: 'Index',
      component: Layout,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('@/components/MyInfo.vue'),
        },
        {
          path: '/clients',
          name: 'Clients',
          component: () => import('@/components/Clients.vue'),
        },
        {
          path: '/backpack',
          name: 'BackPack',
          component: () => import('@/components/BackPack.vue'),
        },
        {
          path: '/login_history',
          name: 'LoginHistory',
          component: () => import('@/components/LoginHistory.vue'),
        },
        {
          path: '/store',
          name: 'Store',
          component: () => import('@/components/Store.vue'),
        },
        {
          path: '/transfer_charge',
          name: 'TransferCharge',
          component: () => import('@/components/TransferCharge.vue'),
        },
        {
          path: '/charges',
          name: 'Charges',
          component: () => import('@/components/ApplyHistory.vue'),
        },
        {
          path: '/transfer_history',
          name: 'TransHistory',
          component: () => import('@/components/TransHistory.vue'),
        },
        {
          path: '/new_good',
          name: 'NewGood',
          component: () => import('@/components/StoreNewGood.vue'),
        },
        {
          path: '/commodity_type',
          name: 'CommodityType',
          component: () => import('@/components/CommodityType.vue'),
        },
        {
          path: '/nodes',
          name: 'Nodes',
          component: () => import('@/components/Nodes.vue'),
        },
      ],
      },
  ]
})
