<template>
  <div class="register">
    <v-layout class="rg-container"
      align-center
      justify-center
    >
      <v-flex class="rg-frame">
    <h1>{{ $t('register.title') }}</h1>
    <v-form ref="form" class="rg-form">
      <v-text-field
        v-model="form.email"
        :rules="emailRules"
        prepend-icon="fa-envelope"
        clearable
        :label="$t('login.email')"
        @keyup.enter.native="login"
        required
      ></v-text-field>
      <v-text-field
        v-model="form.password"
        :rules="passwordRules"
        prepend-icon="fa-lock"
        @keyup.enter.native="login"
        :append-icon="showPwd ? 'fa-eye-slash' : 'fa-eye'"
        :type="showPwd ? 'text' : 'password'"
        @click:append="showPwd = !showPwd"
        :label="$t('login.password')"
        required
      ></v-text-field>
      <v-text-field
        v-model="form.password2"
        :rules="passwordRules2"
        prepend-icon="fa-redo"
        @keyup.enter.native="login"
        :type="showPwd ? 'text' : 'password'"
        @click:append="showPwd = !showPwd"
        :label="$t('register.repeat-password')"
        required
      ></v-text-field>
      <v-text-field
      v-model="form.invite_code"
      prepend-icon="fa-user"
      :label="$t('register.invite-code')"
      @keypress="isNumber($event)">
    </v-text-field>
      <v-layout
        class="rg-panel"
        column
        wrap
        justify-center
        align-center
      >
      <v-flex>
        <v-btn
          @click="registerHandle"
        >
          {{ $t('register.signup') }}
        </v-btn>
      </v-flex>
      </v-layout>
    </v-form>
  </v-flex>
  </v-layout>
  <v-footer
    color="#fbfbfb"
    height="auto"
  >
    <v-layout
    align-center
    justify-center
    >
      <v-flex rg-footer>
    <router-link :to="{ name: 'login' }">{{ $t('register.goback') }}</router-link>
      </v-flex>
    </v-layout>
  </v-footer>
  </div>
</template>

<script>
import {  mapActions } from 'vuex';
// mapState
import { EMAIL_PATTERN } from '@/utils/consts';
export default {
  name: 'Register',
  data () {
    return {
      showPwd: false,
      form: {
        email: '',
        password: '',
        password2: '',
        invite_code: null,
      },
      emailRules: [
        v => !!v || this.$t("login.email-required"),
        v => EMAIL_PATTERN.test(v) || this.$t("login.email-invalid"),
      ],
      passwordRules: [
      v => !!v || this.$t("login.password-required"),
      v => (v && v.length >= 6) || this.$t("login.password-at-less"),
      v => (v && v.length <= 20) || this.$t("login.password-limit"),
      ],
      passwordRules2: [
      v => !!v || this.$t("login.password-required"),
      v => (v && v.length >= 6) || this.$t("login.password-at-less"),
      v => (v && v.length <= 20) || this.$t("login.password-limit"),
      v => (v && v === this.form.password) || this.$t("register.same-password"),
      ],
    };
  },
  methods: {
    ...mapActions('user', ['register']),
    ...mapActions('user', ['register_plus']),
    ...mapActions('alert', ['clear']),
    registerHandle: function() {
      if (this.$refs.form.validate()) {
        let email = this.form.email;
        let password = this.form.password;
        let icode = this.form.invite_code;
        if (icode === null || icode === '') {
          const userData = {
            email: email,
            password: password,
          }
          this.register(userData)
        } else {
          const userData = {
            email: email,
            password: password,
            invite_code: icode,
          }
          this.register_plus(userData)
        }
      }
    },
    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
    }
  },
  mounted() {
    this.$nextTick(function () {
        this.clear();
      })
  },
};
</script>
