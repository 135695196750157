<template>
  <div class="panel-wrapper">
    <span class="logo">
      <img
        src="../assets/logo.png"
        alt=""
      >
    </span>

    <div class="slogan-wrapper">
      <div class="slogan">
      </div>
    </div>
    <v-alert v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</v-alert>
    <div class="panel-content">
    <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TheLogin',
  components: {
    // BaseLangbar,
  },
  data() {
    return {
    };
  },
  computed: {
      ...mapState({
          alert: state => state.alert
      })
  },
  methods: {
      ...mapActions({
          clearAlert: 'alert/clear'
      })
  },
  beforeDestroy() {
      this.clearAlert();
  }
};
</script>

<style lang="scss">
@import '../styles/_login.scss';
</style>
