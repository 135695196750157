<template>
<v-app class="login-con">
  <v-content>
    <base-langbar v-on:langchange="OnLangChange"/>
    <v-container
      fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex class="frame">
          <h1>
            {{ $t('login.title')}}
          </h1>
          <v-form ref="form">
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              prepend-icon="fa-envelope"
              clearable
              :label="$t('login.email')"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              :rules="passwordRules"
              prepend-icon="fa-lock"
              @keyup.enter.native="loginHandle"
              :append-icon="showPwd ? 'fa-eye-slash' : 'fa-eye'"
              :type="showPwd ? 'text' : 'password'"
              @click:append="showPwd = !showPwd"
              :label="$t('login.password')"
              required
            ></v-text-field>
            <v-layout
              class="lg-panel"
              column
              wrap
              justify-center
              align-center
            >
            <v-flex>
              <v-btn
                :loading="loginLoading"
                @click="loginHandle"
              >
                <span slot="loader">{{ $t('login.loading') }}</span>
                {{ $t('login.login') }}
              </v-btn>
            </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
  <v-layout
    justify-space-around
  >
    <v-btn
      small
      color="primary"
      @click="redirectRegister"
    >
      {{ $t('login.notamember') }}
    </v-btn>
    <v-btn
      small
      color="secondary"
      @click="redirectForgotPassword"
    >
      {{ $t('login.forget') }}
    </v-btn>
  </v-layout>
  <v-footer
    color="#fbfbfb"
    height="auto"
  >
    <v-layout
    align-center
    justify-center
    >
      <v-flex ln-footer>
        {{ $t('login.copyrightMessage') }}
      </v-flex>
    </v-layout>
  </v-footer>
</v-app>
</template>
<script>
import BaseLangbar from '@/components/BaseLangbar.vue';
import {  mapActions } from 'vuex';
import { EMAIL_PATTERN } from '@/utils/consts';
// mapState
export default {
  name: 'Login',
  components: {
    BaseLangbar,
  },
  data () {
    return {
      showPwd: false,
      form: {
        email: '',
        password: '',
      },
      loginLoading: false,
      emailRules: [
        v => !!v || this.$t("login.email-required"),
        v => EMAIL_PATTERN.test(v) || this.$t("login.email-invalid"),
      ],
      passwordRules: [
      v => !!v || this.$t("login.password-required"),
      v => (v && v.length >= 6) || this.$t("login.password-at-less"),
      v => (v && v.length <= 20) || this.$t("login.password-limit"),
    ],
    };
  },
  methods: {
    ...mapActions('user', ['login']),
    ...mapActions('alert', ['clear']),
    loginHandle: function() {
      if (this.$refs.form.validate()) {
        let email = this.form.email;
        let password = this.form.password;
        const userData = {
          email: email,
          password: password
        }
        this.login(userData)
        }
    },
    redirectForgotPassword() {
      this.$router.push({ name: 'reset' });
    },
    redirectRegister() {
      this.$router.push({ name: 'register' });
    },
    OnLangChange() {
      this.$refs.form.resetValidation();
    }
  },
  mounted() {
    this.$nextTick(function () {
        const token = localStorage.getItem('token');
        if (token != null) {
          this.$router.push("/dashboard");
        } else { this.clear();}
      })
  },
};
</script>
