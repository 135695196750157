// import AuthenticationService from '@/services/AuthenticationService'
// import router from '@/router'
import  i18n  from '@/i18n.js'

const state = {
  user_items: [],
  general_items: [],
  agency_items: [],
  admin_items: [],
}

state.user_items.push({ title: i18n.t('sidebar.client'), icon: 'fa-users' });
state.user_items.push({ title: i18n.t('sidebar.backpack'), icon: 'fa-box-open' });
state.user_items.push({ title: i18n.t('sidebar.login-history'), icon: 'fa-address-card' });
state.user_items.push({ title: i18n.t('sidebar.apply_history'), icon: 'fa-book-open' });

state.general_items.push({ title: i18n.t('sidebar.store'), icon: 'fa-store-alt' });

state.agency_items.push({ title: i18n.t('sidebar.trans'), icon: 'fa-dolly-flatbed' });
state.agency_items.push({ title: i18n.t('sidebar.trans_history'), icon: 'fa-user-edit' });

state.admin_items.push({ title: i18n.t('sidebar.commodity_type'), icon: 'fa-fighter-jet' });
state.admin_items.push({ title: i18n.t('sidebar.nodes'), icon: 'fa-cloud-sun' });
const mutations = {
};

const actions = {
  change_language() {
    state.user_items = [];
    state.user_items.push({ title: i18n.t('sidebar.client'), icon: 'fa-users' });
    state.user_items.push({ title: i18n.t('sidebar.backpack'), icon: 'fa-box-open' });
    state.user_items.push({ title: i18n.t('sidebar.login-history'), icon: 'fa-address-card' });
    state.user_items.push({ title: i18n.t('sidebar.apply_history'), icon: 'fa-book-open' });

    state.general_items = [];
    state.general_items.push({ title: i18n.t('sidebar.store'), icon: 'fa-store-alt' });

    state.agency_items = [];
    state.agency_items.push({ title: i18n.t('sidebar.trans'), icon: 'fa-dolly-flatbed' });
    state.agency_items.push({ title: i18n.t('sidebar.trans_history'), icon: 'fa-user-edit' });

    state.admin_items = [];
    state.admin_items.push({ title: i18n.t('sidebar.commodity_type'), icon: 'fa-fighter-jet' });
    state.admin_items.push({ title: i18n.t('sidebar.nodes'), icon: 'fa-cloud-sun' });
  }
};

export const sidebar = {
    namespaced: true,
    state,
    actions,
    mutations
};
