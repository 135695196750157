import Api from '@/services/Api'
import ApiAuth from '@/services/ApiAuth'
// import store from '@/store/store'

const headers = {
  "Content-Type": 'application/json;charset=UTF-8',
}
export default {
  verify (info) {
    return Api().post('/api/verify_email', info)
  },
  register (credentials) {
    return Api().post('/api/signup', credentials)
  },
  register_plus (credentials) {
    return Api().post('/api/signup_plus', credentials)
  },
  login (credentials) {
    return Api().post('/api/login', credentials, headers)
  },
  logout (token) {
    return ApiAuth(token).post('/api/logout')
  },
  reset (email) {
    return Api().post('/api/reset', email)
  },
  changepwd (info) {
    return Api().put('/api/reset', info)
  },
  get_changepwd (info) {
    // window.console.log(info)
    return Api().post('/api/reset/check', info, headers)
  },
  get_myinfo (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/myinfo', headers)
  },
  get_clients (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/clients', headers)
  },

  search_users_alike (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/users_search', info, headers)
  },  
  get_backpack (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/warehouse', headers)
  },
  get_login_history (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/history', headers)
  },
  get_apply_history (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/charges', headers)
  },
  get_trans_history (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/trans_his', headers)
  },
  get_goods (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/commodity', headers)
  },
  get_goods_for_admin (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/commodity', headers)
  },
  insert_goods_for_admin (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/commodity', info, headers)
  },
  update_goods_for_admin (token, info) {
    return ApiAuth(token).put('/api/commodity/' + info.id, info.info, headers)
  },
  delete_goods_for_admin (token, info) {
    return ApiAuth(token).delete('/api/commodity/' + info.id,  headers)
  },
  get_types (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/type', headers)
  },
  insert_types (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/type', info.info, headers)
  },
  update_types (token, info) {
    return ApiAuth(token).put('/api/type/' + info.id, info.info, headers)
  },
  delete_types (token, info) {
    return ApiAuth(token).delete('/api/type/' + info.id, headers)
  },
  get_nodes (token) {
    // window.console.log(info)
    return ApiAuth(token).get('/api/nodes', headers)
  },
  insert_nodes (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/nodes', info.info, headers)
  },
  update_nodes (token, info) {
    return ApiAuth(token).put('/api/nodes/' + info.id, info.info, headers)
  },
  delete_nodes (token, info) {
    return ApiAuth(token).delete('/api/nodes/' + info.id, headers)
  },
  apply_self (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/apply_self', info, headers)
  },
  apply (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/apply', info, headers)
  },
  change_cmid (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/change_cmid', info, headers)
  }, 
  transfer (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/transfer', info, headers)
  },
  change_pwd (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/change_pwd', info, headers)
  },
  stop_account (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/stop_account', info, headers)
  },  
  suspend_account (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/suspend_account', info, headers)
  },    
  resume_account (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/resume_account', info, headers)
  },    
  change_name (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/change_name', info, headers)
  },   
  clear_devices (token, info) {
    // window.console.log(info)
    return ApiAuth(token).post('/api/clear_devices', info, headers)
  },     
  warehouse_deposit (token, info) {
    return ApiAuth(token).post('/api/warehouse/deposit', info, headers)
  },
  acquire_email (token) {
    return ApiAuth(token).get('/api/acquire_email', headers)
  },
}
