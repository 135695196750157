import AuthenticationService from '@/services/AuthenticationService'
import router from '@/router'
import  i18n  from '@/i18n.js'
// import VueI18n from 'vue-i18n'

const token = localStorage.getItem('token');
const email = localStorage.getItem('email');
const state = token
    ? { status: { loggedIn: true }, token, name: email, user: {}, user_loaded: false }
    : { status: {}, token: null , name: '', user: {}, user_loaded: false };

const getters = {
      token: state => {
        return state.token
      }
    };

const mutations = {
  setToken (state, token) {
    state.status = { loggedIn: true };
    state.token = token
  },
  setEmail (state, email) {
    state.name = email
  },
  setUser (state, user) {
    state.user = user;
    state.user_loaded= true;
  },
  clearToken(state)
  {
    state.status = {};
    state.token = null;
    state.user_loaded= false;
  },
  reload_nextime(state) {
    state.user_loaded = false;
  }
};

const actions = {
  async login({ dispatch, commit }, user) {
      try {
        const resp = await AuthenticationService.login(user)
        if (resp.status == '200') {
          const token = resp.data.data.token
          commit('setToken', token)
          commit('setEmail', user.email)
          localStorage.setItem('token', token)
          localStorage.setItem('email', user.email)
          router.push("/dashboard")
        }
      } catch(err) {
        dispatch('alert/error', i18n.t('login.username-password-incorrect'), { root: true })
        commit('clearToken')
      }
  },

  async logout({ commit }) {
      try {
        await AuthenticationService.logout(state.token)
        commit('clearToken')
      } catch(err) {
        commit('clearToken')
      }
  },
  async register({ dispatch }, user) {
      try {
        const resp = await AuthenticationService.register(user)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('register.success'), { root: true })
        }
      } catch(err) {
        if (err.response.status == '409') {
          dispatch('alert/error', i18n.t('register.conflict'), { root: true })
        } else {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('register.fail'), { root: true })
        }
      }
  },
  async register_plus({ dispatch }, user) {
      try {
        const resp = await AuthenticationService.register_plus(user)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('register.success'), { root: true })
        }
      } catch(err) {
        if (err.response.status == '409') {
          dispatch('alert/error', i18n.t('register.conflict'), { root: true })
        } else {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('register.fail'), { root: true })
        }
      }
  },
  async reset({ dispatch }, email) {
      try {
        const resp = await AuthenticationService.reset(email)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('reset.success'), { root: true })
        }
      } catch(err) {
        if (err.response.status == '429') {
          dispatch('alert/error', i18n.t('reset.try-later'), { root: true })
        } else {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('reset.fail'), { root: true })
        }
      }
  },
  async changepwd({ dispatch }, info) {
      try {
        const resp = await AuthenticationService.changepwd(info)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('changepwd.success'), { root: true })
        }
      } catch(err) {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('changepwd.fail'), { root: true })
      }
  },
  async get_changepwd({ dispatch }, info) {
      try {
        const resp = await AuthenticationService.get_changepwd(info)
        if (resp.status == '200') {
          dispatch('alert/info', i18n.t('changepwd.valid'), { root: true })
        }
      } catch(err) {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('changepwd.invalid'), { root: true })
      }
  },
  async verify({ dispatch }, info) {
      try {
        const resp = await AuthenticationService.verify(info)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('email.confirm'), { root: true })
        }
      } catch(err) {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('email.verify-fail'), { root: true })
        }
    },
  async acquire_verify({ dispatch, rootGetters }) {
      try {
        const token = rootGetters['user/token'];
        const resp = await AuthenticationService.acquire_email(token)
        if (resp.status == '200') {
          dispatch('alert/success', i18n.t('email.send-again'), { root: true })
        }
      } catch(err) {
          // window.console.log(resp)
          dispatch('alert/error', i18n.t('email.send-fail'), { root: true })
        }
    },
  async get_myinfo({ commit }) {
      try {
        const resp = await AuthenticationService.get_myinfo(state.token)
        if (resp.status == '200') {
          commit('setUser', resp.data.data)
        }
      } catch(err) {
          window.console.log(err)
        }
    },
  reload_nextime({ commit }) {
      commit('reload_nextime');
    }
};

export const user = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
