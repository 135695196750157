import AuthenticationService from '@/services/AuthenticationService'
// import Vue from 'vue'

const state = {
  loaded: false,
  apply_his: []
};

const actions = {
    async get_apply_history({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_apply_history(token);
          if (resp.status == '200') {
            commit('setApplyHistory', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    clear({ commit }) {
        commit('clear');
    },
    reload_nextime({ commit }) {
        commit('reload_nextime');
    }
};

const mutations = {
    setApplyHistory(state, _his) {
        state.apply_his = _his;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.apply_his = [];
    },
    reload_nextime(state) {
      state.loaded = false;
    }
};

export const apply_history = {
    namespaced: true,
    state,
    actions,
    mutations
};
