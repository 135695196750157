import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router';
import store from './store/';
import i18n from './i18n'

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFontAwesome,faFacebook,faAlipay } from '@fortawesome/free-brands-svg-icons'
import vuetify from './plugins/vuetify';
import moment from 'moment';

library.add(fas, faFontAwesome, faFacebook, faAlipay)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

NProgress.configure({
  template: `<div class="bar" role="bar" style="background:#1DE2C3;"><div class="peg"></div></div>
  <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`,
  showSpinner: false,
});

Vue.router = router;
Vue.store = store;

Vue.router.beforeEach((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

Vue.router.afterEach(() => {
  NProgress.done();
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD')
  }
}),

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
