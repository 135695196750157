import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
// import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css';

import colors from 'vuetify/lib/util/colors';
// import Snackbar from '../components/snackbar/index';
import zhHans from 'vuetify/es5/locale/zh-Hans';

Vue.use(Vuetify);

// Vue.prototype.$message = Snackbar;

export default new Vuetify({
  lang: {
     locales: { 'zh-CN': zhHans },
      current: 'en'
  },
  icons: {
    iconfont: 'fa',
  },
  theme: {
    light: true,
  themes: {
  light: {
    primary: colors.purple,
    secondary: colors.grey.darken1,
    accent: colors.shades.black,
    error: colors.red.accent3,
  },
  dark: {
    primary: colors.blue.lighten3,
    },
  },
  },
});
