import AuthenticationService from '@/services/AuthenticationService'
import  i18n  from '@/i18n.js'

const state = {
  loaded: false,
  nodes: []
};

const actions = {
    async get_nodes({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_nodes(token);
          if (resp.status == '200') {
            commit('setNodes', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async insert_nodes({ dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.insert_nodes(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async update_nodes({ dispatch, rootGetters }, info) {
        try {
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.update_nodes(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async delete_nodes({ dispatch, rootGetters }, info) {
        try {
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.delete_nodes(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    setNodes(state, _nodes) {
        state.nodes = _nodes;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.clients = null;
    }
};

export const nodes = {
    namespaced: true,
    state,
    actions,
    mutations
};
