import AuthenticationService from '@/services/AuthenticationService'
import  i18n  from '@/i18n.js'

const state = {
  loaded: false,
  types: []
};

const actions = {
    async get_types({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_types(token);
          if (resp.status == '200') {
            commit('setTypes', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async insert_types({ dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.insert_types(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async update_types({ dispatch, rootGetters }, info) {
        try {
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.update_types(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async delete_types({ dispatch, rootGetters }, info) {
        try {
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.delete_types(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
          }
        } catch(err) {
            dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    setTypes(state, _types) {
        state.types = _types;
        state.loaded  = true;
    },
    clear(state) {
        state.loaded = false;
        state.clients = null;
    }
};

export const commodity_type = {
    namespaced: true,
    state,
    actions,
    mutations
};
