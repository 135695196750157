import AuthenticationService from '@/services/AuthenticationService'
import  i18n  from '@/i18n.js'

const state = {
  loaded: false,
  goods: [],
  selected:{},
};

const actions = {
    async get_goods({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_goods(token);
          if (resp.status == '200') {
            commit('setGoods', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async get_goods_for_admin({ commit, rootGetters }) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.get_goods_for_admin(token);
          if (resp.status == '200') {
            commit('setGoods', resp.data.data)
          }
        } catch(err) {
            window.console.log(err)
          }
    },
    async insert_goods_for_admin({ commit, dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.insert_goods_for_admin(token, info.info);
          if (resp.status == '201') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
            commit('reload_nextime');
          }
        } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async update_goods_for_admin({ commit, dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.update_goods_for_admin(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
            commit('reload_nextime');
          }
        } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    async delete_goods_for_admin({ commit, dispatch, rootGetters }, info) {
        try {
          // window.console.log(store.getters);
          const token = rootGetters['user/token'];
          const resp = await AuthenticationService.delete_goods_for_admin(token, info);
          if (resp.status == '200') {
            dispatch('alert/success', i18n.t('admin.success'), { root: true });
            commit('reload_nextime');
          }
        } catch(err) {
          dispatch('alert/error', i18n.t('admin.fail'), { root: true });
          }
    },
    new_item( { commit }) {
      commit('new_item');
    },
    edit_item( { commit }, item ) {
      commit('edit_item', item);
    },
    clear({ commit }) {
      commit('clear');
    },
    reload_nextime({ commit }) {
        commit('reload_nextime');
      }
};

const mutations = {
    setGoods(state, _goods) {
      state.goods = _goods;
      state.loaded  = true;
    },
    edit_item(state, _item) {
      state.selected = _item;
    },
    new_item(state) {
      state.selected.id = 0;
    },
    clear(state) {
      state.loaded = false;
      state.clients = null;
    },
    reload_nextime(state) {
      state.loaded = false;
    }
};

export const store = {
    namespaced: true,
    state,
    actions,
    mutations
};
